import { APP_PREFIX_PATH } from "configs/AppConfig";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CustomButton from "utils/appointment/customButton";
import CustomTable from "utils/appointment/customTable";
import CustomCard from "utils/customCard";
import CategoryForm from "./categoryForm";

import {
  mockSubCAtegoriesData,
  SubcategoriesColumns,
} from "./cotegoriesColumn";
import { convertToBase64 } from "hooks/useUtils";
import { useEcommerce } from "zustand/store/ecommerceStore";
import { Modal } from "antd";

const CategoryDetail = (props) => {
  const category = props.location.state;
  const [mode, setMode] = useState("");
  const [fileList, setFileList] = useState([]);
  
  const { loading, fetchSubCategoriesByCategoryId, addSubCategory ,subCategoriesData} =
    useEcommerce((state) => ({
      loading: state.loading,
      fetchSubCategoriesByCategoryId: state.fetchSubCategoriesByCategoryId,
      addSubCategory: state.addSubCategory,
      subCategoriesData:state.subCategoriesData
    }));

  useEffect(() => {
    fetchSubCategoriesByCategoryId(category?.id);
  }, [fetchSubCategoriesByCategoryId, category]);

  const handleAddForm = async (values, base64) => {
    if (fileList.length > 0) {
      const base64 = await convertToBase64(fileList);
      values["category"]=category; 
      values["image"] = base64[0].base64.split(",")[1];
    
      addSubCategory(values);
      setMode("")
    }
  };

  const renderDetail = () => {
    return {
      key: "Action",
      title: "Action",
      render: (category) => (
        <CustomButton textbutton="Detail" handleClick={() => {}} />
      ),
    };
  };
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [currentImage, setCurrentImage] = useState('');
  
  const handleImageClick = (url) => {
    setCurrentImage(url);
    setIsModalVisible(true);
  };
  
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
    
    <CustomCard
      loading={loading}
      title={
        mode !== "Add"
          ? "Liste des sous-categories"
          : "Ajouter une nouvelle sous-categorie"
      }
      extra={
        <div>
          {mode !== "Add" && (
            <CustomButton
              textbutton="Ajouter Sous-category"
              handleClick={() => setMode("Add")}
              type="primary"
              style={{ marginLeft: 5, marginRight: 5 }}
            />
          )}
        </div>
      }
      children={
        <>
          {mode !== "Add" ? (
            <CustomTable
              dataList={subCategoriesData}
              columns={[...SubcategoriesColumns(handleImageClick), renderDetail()]}
            />
          ) : (
            <CategoryForm
              category="Sous-categorie"
              handleAddForm={handleAddForm}
              fileList={fileList}
              setFileList={setFileList}
            />
          )}
        </>
      }
    />
     <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          src={currentImage}
          alt="Large View"
          style={{ width: '100%', height: 'auto' }}
        />
      </Modal>
    </>
  );
};

export default CategoryDetail;
