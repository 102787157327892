import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import TypicalResponseList from "./Typical-responses";
import ClaimsDetail from "./claims/claimsDetail";
import PatientAccountDeposit from "./patients/patientAccountDeposit";
import ServiceInvoicing from "./HomeCare-and-sampling/serviceInvoicing";
import OrderList from "./ecommerce";
import ProductList from "./ecommerce/product/productList";
import CategoryList from "./ecommerce/categorie/categoryList";
import CategoryDetail from "./ecommerce/categorie/categoryDetail";

export const AppViews = () => {
  const roles = JSON.parse(localStorage.getItem("roles") || "[]");
  const role = roles[0] || "";
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/home`}
          component={role!=="CASHIER"?lazy(() => import(`./home`)):lazy(()=>import('./Payments/general/listPayments'))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/doctors`}
          component={lazy(() => import(`./doctors`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/appointment`}
          component={lazy(() => import(`./appointments`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/medical-exams`}
          component={lazy(() => import(`./MedicalExam`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/tele-consultation/list`}
          component={lazy(() => import(`./teleconsultation`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/logs`}
          component={lazy(() => import(`./Logs`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/consultation`}
          component={lazy(() => import(`./Consultation`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/eprescription`}
          component={lazy(() => import(`./Eprescription`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/forms`}
          component={lazy(() => import(`./Formulaire`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/articles`}
          component={lazy(() => import(`./Article`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/payments`}
          component={lazy(() => import(`./Payments`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/transaction`}
          component={lazy(() => import(`./Transaction`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settlements`}
          component={lazy(() => import(`./settlements`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/notification`}
          component={lazy(() => import(`./Notification`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/enterprise`}
          component={lazy(() => import(`./Enterprise`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/dicoms`}
          component={lazy(() => import(`./Dicoms`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/requested-doctors`}
          component={lazy(() => import(`./RequestedDoctors`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/requests`}
          component={lazy(() => import(`./requests`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/healthprofessional`}
          component={lazy(() => import(`./health-professional`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/patients`}
          component={lazy(() => import(`./patients`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/patient/balance`}
          component={(props) => <PatientAccountDeposit {...props} />}
        />

        <Route
          path={`${APP_PREFIX_PATH}/typicals-responses`}
          component={TypicalResponseList}
        />

        <Route
          path={`${APP_PREFIX_PATH}/biosensors`}
          component={lazy(() => import(`./Biosensor`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/folder-creation-requests/list`}
          component={lazy(() => import(`./folder-creation-requests`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/folder-creation-requests/details`}
          component={lazy(() => import(`./folder-creation-requests/Detail`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/home-care-requests`}
          component={lazy(() => import("./homecare"))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/in-home-sampling-requests`}
          component={lazy(() => import("./inHomeSampling"))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/action/set-price`}
          component={(props) => <ServiceInvoicing {...props} />}
        />

        <Route
          path={`${APP_PREFIX_PATH}/laboratory`}
          component={lazy(() => import("./laboratory"))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/claims-request`}
          component={lazy(() => import("./claims"))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/claims/claims-request-detail`}
          component={(props) => <ClaimsDetail {...props} />}
        />

        <Route
          path={`${APP_PREFIX_PATH}/order-requests`}
          component={() => <OrderList />}
        />

        <Route
          path={`${APP_PREFIX_PATH}/products`}
          component={() => <ProductList />}
        />
          <Route
       path={`${APP_PREFIX_PATH}/probs/list`}
       component={lazy(() => import(`./Probs/probsList`))} />
    

        <Route
          path={`${APP_PREFIX_PATH}/categories`}
          component={() => <CategoryList />}
        />

        <Route
          path={`${APP_PREFIX_PATH}/category/detail`}
          component={(props) => <CategoryDetail {...props} />}
        />
        <Route
          path={`${APP_PREFIX_PATH}/team`}
          component={lazy(()=>import('./team/team'))}
        />
          <Route
          path={`${APP_PREFIX_PATH}/actifSpecialties`}
          component={lazy(()=>import('./actifSpescialities/actifpescialitties'))}
        />
          <Route
          path={`${APP_PREFIX_PATH}/header`}
          component={lazy(()=>import('./header/header'))}
        />

        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
        
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
