import { LOGIN_PATH } from "../configs/AppConfig";
import axios from "axios";
import decode from "jwt-decode";
import { AUTH_TOKEN } from "../redux/constants/Auth";
import firebase, { auth } from "firebase";
import FirebaseService from "./FirebaseService";
import { signInWithCustomToken } from "auth/FirebaseAuth";
import fetch from "../auth/FetchInterceptor";
const JwtAuthService = {};

JwtAuthService.login = function (data) {
  return axios.post(LOGIN_PATH, data, {
    headers: {
      "Content-Type": "application/json",

    },
  });
};
JwtAuthService.logout = function () {
  localStorage.clear();
};
JwtAuthService.isAuthenticated = function () {
  let token = localStorage.getItem(AUTH_TOKEN);
  if (token == null) {
    return false;
  }
  return true;
};

JwtAuthService.saveToken = async function (jwt) {
  localStorage.setItem(AUTH_TOKEN, jwt);
  const decodedToken = decode(jwt);


  if (decodedToken.firebase_auth_token) signInWithCustomToken(decodedToken.firebase_auth_token);

  let roles1 = decodedToken.roles;
  console.log(roles1)
  localStorage.setItem("username", decodedToken.username);
console.log("token decoded",decodedToken)
const rolesWithLocalStorage = ["ADMIN", "COORDINATEUR", "COMPTABLE", "SECRETAIRE"];
const rolesWithEnterpriseFetch = ["ENTERPRISE", "CASHIER"];

for (let role of roles1) {
  if (rolesWithLocalStorage.includes(role)) {
    localStorage.setItem("roles", JSON.stringify(decodedToken.roles));
    return true;
  } 
  
  if (rolesWithEnterpriseFetch.includes(role)) {
    localStorage.setItem("roles", JSON.stringify(decodedToken.roles));
    try {
      const data = await fetch.get("/auth/get-my-info");

      if (data && data.id) {
        localStorage.setItem("name", data.name);
        localStorage.setItem("enterpriseName", data.enterpriseName);
        console.log(data.enterpriseName);  
        localStorage.setItem("EnterpriseId", data.id);
        console.log("EnterpriseId", data.id);
      } else {
        console.error("No ID found in data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    return true;
  }
}

};
export default JwtAuthService;
