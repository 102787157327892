import React, { useState } from "react";

export const categoriesColumns = (handleImageClick) => [
  {
    key: "1",
    title: "id",
    dataIndex: "id",
  },
  {
    key: "name",
    title: "Categorie",
    dataIndex: "name",
  },

  {
    key: "image",
    title: "Image",
    dataIndex: "urlImage",
    render: (text, record) => {
      return (
        <>
          <img src={record.urlImage} alt="Image" style={{ width: "100px", height: "auto" }}
           onClick={() => handleImageClick(record.urlImage)}/>
          

        </>
      );
    }
  },
];

export const SubcategoriesColumns =  (handleImageClick) =>  [
  {
    key: "1",
    title: "id",
    dataIndex: "id",
  },
  {
    key: "name",
    title: "Nom",
    dataIndex: "name",
  },

  {
    key: "image",
    title: "Image",
    dataIndex: "urlImage",
    render: (text, record) => {
      return (
        <>
          <img src={record.urlImage} alt="Image" style={{ width: "100px", height: "auto" }}
           onClick={() => handleImageClick(record.urlImage)}/>
          

        </>
      )}

  },
];

export const mockData = [
  {
    id: 1,
    Category: "Electronics",
    subCategory: "Mobile Phones",
    target: "General Public",
    serviceName: "Edit",
  },
  {
    id: 2,
    Category: "Home Appliances",
    subCategory: "Refrigerators",
    target: "Homeowners",
    serviceName: "Delete",
  },
  {
    id: 3,
    Category: "Fashion",
    subCategory: "Men's Clothing",
    target: "Fashion Enthusiasts",
    serviceName: "Edit",
  },
  {
    id: 4,
    Category: "Beauty",
    subCategory: "Skincare",
    target: "Beauty Lovers",
    serviceName: "Delete",
  },
  {
    id: 5,
    Category: "Sports",
    subCategory: "Fitness Equipment",
    target: "Athletes",
    serviceName: "Edit",
  },
  {
    id: 6,
    Category: "Books",
    subCategory: "Fiction",
    target: "Readers",
    serviceName: "Delete",
  },
  {
    id: 7,
    Category: "Toys",
    subCategory: "Educational Toys",
    target: "Parents",
    serviceName: "Edit",
  },
  {
    id: 8,
    Category: "Automotive",
    subCategory: "Car Accessories",
    target: "Car Owners",
    serviceName: "Delete",
  },
];

export const mockSubCAtegoriesData = [
  {
    id: 1,
    subCategoryName: "Smartphones",
    Category: "Electronics",
    image: "smartphones.jpg",
  },
  {
    id: 2,
    subCategoryName: "Refrigerators",
    Category: "Home Appliances",
    image: "refrigerators.jpg",
  },
  {
    id: 3,
    subCategoryName: "Men's Clothing",
    Category: "Fashion",
    image: "mens_clothing.jpg",
  },
  {
    id: 4,
    subCategoryName: "Skincare",
    Category: "Beauty",
    image: "skincare.jpg",
  },
  {
    id: 5,
    subCategoryName: "Yoga Mats",
    Category: "Sports",
    image: "yoga_mats.jpg",
  },
  {
    id: 6,
    subCategoryName: "Fiction",
    Category: "Books",
    image: "fiction_books.jpg",
  },
  {
    id: 7,
    subCategoryName: "Board Games",
    Category: "Toys",
    image: "board_games.jpg",
  },
  {
    id: 8,
    subCategoryName: "Car Accessories",
    Category: "Automotive",
    image: "car_accessories.jpg",
  },
];
