import React, { useEffect, useState } from "react";
import CustomTable from "utils/appointment/customTable";
import CustomCard from "utils/customCard";
import { mockProductData, productsColumns } from "./productColumns";
import CustomButton from "utils/appointment/customButton";
import ProductForm from "./productForm";
import { convertToBase64 } from "hooks/useUtils";
import { useEcommerce } from "zustand/store/ecommerceStore";
import { Button, Modal } from "antd";

const ProductList = () => {
  const [mode, setMode] = useState("");
  const [fileList, setFileList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [images, setImages] = useState([]);
  const openGallery = (images, initialImageId) => {
    const initialIndex = images.findIndex((image) => image.id === initialImageId);
    setImages(images);
    setCurrentImageIndex(initialIndex);
    setIsModalVisible(true);
  };

  const closeGallery = () => {
    setIsModalVisible(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length); 
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length); 
   
  };

  const { loading, fetchProducts, productsData, addProduct ,subCategoriesData,fetchSubCategories } = useEcommerce(
    (state) => ({
      loading: state.loading,
      fetchProducts: state.fetchProducts,
      addProduct: state.addProduct,
      productsData: state.productsData,
      fetchSubCategories: state.fetchSubCategories,
      subCategoriesData:state.subCategoriesData


    })
  );

  useEffect(() => {
    fetchProducts();
    fetchSubCategories();
  }, [fetchProducts,fetchSubCategories]);

  const handleAddForm = async (values) => {
    if (fileList.length > 0) {
      const base64 = await convertToBase64(fileList);
      console.log(base64)
      values["attachments"] = base64.map(base => base.base64.split(",")[1]);
      values["sousCategory"] = {
        "id": values.sousCategory
      };
    
      addProduct(values);
      setMode("")
    }
  };

  return (
    <div>
      <Modal
        visible={isModalVisible}
        onCancel={closeGallery}
        footer={null}
        width={800}
        style={{ textAlign: 'center' }}
      >
        <div>
          <img
            src={images[currentImageIndex]?.url}
            alt={`Image ${images[currentImageIndex]?.id}`}
            style={{ width: '100%', height: 'auto' }}
          />
        </div>
        <div style={{ marginTop: '10px' }}>
          <Button onClick={prevImage} style={{ marginRight: '10px' }}>Previous</Button>
          <Button onClick={nextImage}>Next</Button>
        </div>
      </Modal>
      <CustomCard
        loading={loading}
        title="Liste des produits"
        extra={
          <div>
            {mode !== "Add" && (
              <CustomButton
                textbutton="Ajouter un Produit"
                handleClick={() => setMode("Add")}
                type="primary"
              />
            )}
          </div>
        }
        children={
          <>
            {mode !== "Add" ? (
              <CustomTable dataList={productsData} columns={productsColumns(openGallery)} />
            ) : (
              <ProductForm
                handleAddForm={handleAddForm}
                fileList={fileList}
                setFileList={setFileList}
                sousCategories={subCategoriesData}
              />
            )}
          </>
        }
      />
    </div>
  );
};

export default ProductList;
