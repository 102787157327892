import { create } from "zustand";
import { HomeCareRequests, updateHomeCareRequest } from "constants/ApiConstant";
import fetch from "../../auth/FetchInterceptor";
import { alertMessage, goBack } from "hooks/useUtils";

export const useHomeCare = create((set, get) => ({
  loading: false,
  requests: [],
  error: null,
  homeSamplings: [],
  note: "",
  setNote: (value) => set({ note: value }),
  requiredValue: false,
  setRequiredValue: () => set({ requiredValue: true }),
  fileList: [],
  setFileList: (file) => set({ fileList: file }),

  isOpenResultModal: false,
  setOpenResultModal: () => set({ isOpenResultModal: true }),
  closeResultModal: () => set({ isOpenResultModal: false }),

  fetchRequests: () => {
    set({ loading: true });
    fetch
      .get(HomeCareRequests)
      .then((response) => {
        set({ requests: response.reverse(), loading: false });
      })
      .catch((error) => {
        set({ loading: false, error });
      });
  },

  setRequestPrice: async (id, data, requestType) => {
    console.log("first", data);

    const api = `${updateHomeCareRequest}${id}`;
    const body = { price: parseFloat(data) };
    set({ loading: true });

    try {
      await fetch.patch(api, data).then(() => {
        alertMessage("Price set with success", "success");
        set((state) => {
          state.loading = false;
          state.fetchRequests(requestType);
          return state;
        });
        goBack();
      });
    } catch (error) {
      set({ loading: false, error: error?.message });
    }
  },

  uploadResultFile: async (payload, id) => {
    set({ loading: true });
    try {
      await fetch.patch(`/v2/yata/home-health/${id}/result`, payload);
      set({ loading: false, note: "", fileList: [] });
      goBack();
    } catch (error) {
      console.error(error?.message);
      set({ loading: false, error: error?.message });
    }
  },

  cancelRequest: async (id, requestType) => {
    const api = `${updateHomeCareRequest}${id}?cancel=true`;
    set({ loading: true });
    try {
      await fetch.patch(api);
      alertMessage("Canceled", "success");
      get().fetchRequests(requestType);
    } catch (error) {
      console.error(error);
      set({ loading: false, error: error?.message });
    }
  },

  fetchRequestByStatus: (status) => {
    set({ loading: true });
    fetch
      .get(`${HomeCareRequests}?status=${status}`)
      .then((response) =>
        set({ loading: false, homeCareRequests: response.reverse() })
      )
      .catch((error) => set({ loading: false, error: error?.message }));
  },

  uploadReceiptFile: (id, payload) => {
    set({ loading: true });
    fetch
      .patch(`/v2/yata/home-health/upload/${id}/receipt`, payload)
      .then(() => {
        alertMessage("Recu envoyer", "success");
        set({ loading: false });
      })
      .catch((error) => {
        alertMessage(error?.message, "error");
        set({ loading: false, error: error?.message });
      });
  },
}));
