import React, { useEffect, useState } from "react";
import CustomButton from "utils/appointment/customButton";
import CustomTable from "utils/appointment/customTable";
import CustomCard from "utils/customCard";
import { categoriesColumns, mockData } from "./cotegoriesColumn";
import CategoryForm from "./categoryForm";
import { useHistory } from "react-router-dom";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { convertToBase64 } from "hooks/useUtils";
import { useEcommerce } from "zustand/store/ecommerceStore";
import { Modal } from "antd";

const CategoryList = () => {
  const history = useHistory();
  const [mode, setMode] = useState("");
  const [fileList, setFileList] = useState([]);

  const { loading, fetchCategories, addCategory,categoriesData } = useEcommerce((state) => ({
    loading: state.loading,
    fetchCategories: state.fetchCategories,
    addCategory: state.addCategory,
    categoriesData:state.categoriesData
  }));

  const handleAddForm = async (values) => {
    if (fileList.length > 0) {
      const base64 = await convertToBase64(fileList);
      values["image"] = base64[0].base64.split(",")[1];

      console.log(values)
      addCategory(values);
      
      setMode("")

    }
  };
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [currentImage, setCurrentImage] = useState('');
  
  const handleImageClick = (url) => {
    setCurrentImage(url);
    setIsModalVisible(true);
  };
  
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const renderDetail = () => {
    return {
      key: "Action",
      title: "Action",
      render: (category) => (
        <CustomButton
          textbutton="Detail"
          handleClick={() =>
            history.push({
              pathname: `${APP_PREFIX_PATH}/category/detail`,
              state: category,
            })
          }
        />
      ),
    };
  };

  return (
    <>
     <CustomCard
      loading={loading}
      title={
        mode !== "Add"
          ? "Liste des categories"
          : "Ajouter une nouvelle categorie"
      }
      extra={
        <div>
          {mode !== "Add" && (
            <CustomButton
              textbutton="Ajouter une catégorie"
              handleClick={() => setMode("Add")}
              type="primary"
              style={{ marginLeft: 5, marginRight: 5 }}
            />
          )}
        </div>
      }
      children={
        <>
          {mode !== "Add" ? (
            <CustomTable
              dataList={categoriesData}
              columns={[...categoriesColumns(handleImageClick), renderDetail()]}
            />
          ) : (
            <CategoryForm
              category="categorie"
              handleAddForm={handleAddForm}
              fileList={fileList}
              setFileList={setFileList}
            />
          )}
        </>
      }
    />
    <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          src={currentImage}
          alt="Large View"
          style={{ width: '100%', height: 'auto' }}
        />
      </Modal>
    </>
   
  );
};

export default CategoryList;
