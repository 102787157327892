import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import history from "../history";
import { AUTH_TOKEN } from "redux/constants/Auth";
import { message, notification } from "antd";
import {
  AUTHENTICATION_FAILED,
  ERROR,
  LOGIN_ERROR,
  NOTFOUND,
  SERVER_ERROR,
  TIMEOUT,
} from "constants/exception";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

// Config
const ENTRY_ROUTE = "/auth/login";
const TOKEN_PAYLOAD_KEY = "authorization";
const PUBLIC_REQUEST_KEY = "public-request";

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN);

    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = jwtToken;
    }

    if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: ERROR,
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log(error);
    let notificationParam = {
      message: "",
    };

    // Remove token and redirect
    if (error.response.status === 400 || error.response.status === 403) {
      notificationParam.message = AUTHENTICATION_FAILED;
      notificationParam.description = LOGIN_ERROR;
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem("EnterpriseId")
      history.push(ENTRY_ROUTE);
      // window.location.reload();
      // message.error("Vous n'avez pas l'autorisation");
    }

    if (error.response.status === 404) {
      notificationParam.message = NOTFOUND;
    }

    if (error.response.status === 500) {
      notificationParam.message = SERVER_ERROR;
    }

    if (error.response.status === 508) {
      notificationParam.message = TIMEOUT;
    }

    notification.error(notificationParam);

    return Promise.reject(error);
  }
);

export default service;
