import { Form, Input, Select } from "antd";
import React, { useState } from "react";

import CustomUploadFile from "utils/customUpload";
import CustomButton from "utils/appointment/customButton";
import { Option } from "antd/lib/mentions";
import { mockData } from "../categorie/cotegoriesColumn";

const ProductForm = (props) => {
  const { handleAddForm, fileList, setFileList  ,sousCategories} = props;
  const [form] = Form.useForm();

  const handleForm = (values) => {
    handleAddForm(values);
  };

  const onFinishFailed = () => {
    console.log("");
  };
  return (
    <Form
      initialValues={{ remember: true }}
      onFinish={handleForm}
      onFinishFailed={onFinishFailed}
      autoComplete="on"
      form={form}
    >
      <Form.Item
        label={`Nom du produit`}
        name="title"
        required={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label={`Prix`} name="price" required={[{ required: true }]}>
        <Input type="number" />
      </Form.Item>

      <Form.Item
        label={`Stock`}
        name="stock"
        required={[{ required: true }]}
      >
        <Input type="number" />
      </Form.Item>

      <Form.Item
        label="Sous-Categorie"
        name="sousCategory"
        rules={[{ required: true, message: "Sous-categorie est obligatoire" }]}
      >
        <Select placeholder="Choisir sous-category">
          {sousCategories.map((data) => (
            <Option value={data.id}>{data.name}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Photo du categorie"
        name="fileList"
        required={[{ required: true }]}
      >
        <CustomUploadFile setFileList={setFileList} fileList={fileList} />
      </Form.Item>

      <Form.Item>
        <CustomButton
          htmlType="submit"
          type="primary"
          textbutton="Enregistre"
        />
      </Form.Item>
    </Form>
  );
};

export default ProductForm;
