import React from "react";
export const productsColumns =(openGallery)=> [
  {
    key: "1",
    title: "id",
    dataIndex: "id",
  },
  {
    key: "name",
    title: "Produit",
    dataIndex: "title",
  },
  {
    key: "SousCategory",
    title: "SousCategory",
    dataIndex: "sousCategory",
    render: (text, record) => record.sousCategory?.name
  },
  {
    key: "#quantity",
    title: "quantite",
    dataIndex: "stock",
  },
  {
    key: "price",
    title: "prix",
    dataIndex: "price",
  },
  {
    key: "cibele",
    title: "Cible",
    dataIndex: "cible",
  },
  {
    key: 'images',
    title: 'Pictures',
    dataIndex: 'images',
    render: (text, record) => (
      <div style={{ display: 'flex', gap: '10px' }}>
        {record?.images?.map((image) => (
          <img
            key={image.id}
            src={image.url}
            alt={`Image ${image.id}`}
            style={{ width: '50px', height: '50px', objectFit: 'cover', cursor: 'pointer' }}
            onClick={() => openGallery(record.images, image.id)} 
          />
        ))}
      </div>
    ),
  },
];

export const mockProductData = [
  {
    id: 1,
    product: "Smartphone",
    subCategory: "Electronics",
    quantity: 50,
    price: 699.99,
    cible: "General Public",
    image: "smartphone.jpg",
  },
  {
    id: 2,
    product: "Washing Machine",
    subCategory: "Home Appliances",
    quantity: 20,
    price: 499.99,
    cible: "Homeowners",
    image: "washing_machine.jpg",
  },
  {
    id: 3,
    product: "T-Shirt",
    subCategory: "Fashion",
    quantity: 100,
    price: 19.99,
    cible: "Fashion Enthusiasts",
    image: "tshirt.jpg",
  },
  {
    id: 4,
    product: "Moisturizer",
    subCategory: "Beauty",
    quantity: 75,
    price: 29.99,
    cible: "Beauty Lovers",
    image: "moisturizer.jpg",
  },
  {
    id: 5,
    product: "Yoga Mat",
    subCategory: "Sports",
    quantity: 30,
    price: 39.99,
    cible: "Athletes",
    image: "yoga_mat.jpg",
  },
  {
    id: 6,
    product: "Mystery Novel",
    subCategory: "Books",
    quantity: 150,
    price: 14.99,
    cible: "Readers",
    image: "mystery_novel.jpg",
  },
  {
    id: 7,
    product: "Board Game",
    subCategory: "Toys",
    quantity: 60,
    price: 29.99,
    cible: "Families",
    image: "board_game.jpg",
  },
  {
    id: 8,
    product: "Car Cleaning Kit",
    subCategory: "Automotive",
    quantity: 25,
    price: 49.99,
    cible: "Car Owners",
    image: "car_cleaning_kit.jpg",
  },
];
